import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import firebase from 'firebase/compat/app';
import { getMimeType } from '../../utils/utils';

const Attachment = ({ fileName }) => {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    const storage = firebase.storage();
    const fileRef = storage.ref(
      `private/messages/${fileName.replace('.opus', '.mp3')}`,
    );
    fileRef.getDownloadURL().then(url => {
      setSrc(url);
    });
  }, [fileName]);

  const renderAttachment = () => {
    const mimeType = getMimeType(fileName) || '';
    if (mimeType.startsWith('image/')) {
      return <img src={src} title={fileName} alt="" />;
    }
    if (mimeType.startsWith('video/')) {
      return (
        <video controls title={fileName}>
          <source src={src} type={mimeType} />
        </video>
      );
    }
    if (mimeType.startsWith('audio/')) {
      return <audio controls src={src} title={fileName} />;
    }
    return (
      <a href={src} download={fileName}>
        {fileName}
      </a>
    );
  };

  if (src) return renderAttachment();
  return `Loading ${fileName}...`;
};

Attachment.propTypes = {
  fileName: PropTypes.string.isRequired,
};

export default Attachment;
