/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import { parseString } from 'whatsapp-chat-parser';
// import { readFileSync } from 'fs';

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import * as firebaseui from 'firebaseui';
import MessageViewer from './components/MessageViewer/MessageViewer';
import * as S from './style';

import 'firebaseui/dist/firebaseui.css';

const DEFAULT_LOWER_LIMIT = 1;
const DEFAULT_UPPER_LIMIT = 50;

const firebaseConfig = {
  apiKey: 'AIzaSyCTKkXw3Bv3U9BVWqBhXFVsFz4I1zwUCQE',
  authDomain: 'jonandjackie.firebaseapp.com',
  projectId: 'jonandjackie',
  storageBucket: 'jonandjackie.appspot.com',
  messagingSenderId: '700402816515',
  appId: '1:700402816515:web:ab4d44230e86e4b85fe036',
};

const showError = (message, err) => {
  console.error(err || message); // eslint-disable-line no-console
  alert(message); // eslint-disable-line no-alert
};

const replaceEncryptionMessageAuthor = messages =>
  messages.map((message, i) => {
    if (i < 10 && message.message.includes('end-to-end')) {
      return {
        ...message,
        message:
          'This is my interactive recreation of our WhatsApp history showing how we met, fell in love, and got back together. I wanted to put it in a place where it would never get lost and you could keep it forever. Happy anniversary, cuchi cuchi. I love you.',
        author: 'System',
      };
    }
    return message;
  });

const App = () => {
  const [messages, setMessages] = useState([]);
  const [activeUser, setActiveUser] = useState('');
  const [lowerLimit, setLowerLimit] = useState(DEFAULT_LOWER_LIMIT);
  const [upperLimit, setUpperLimit] = useState(DEFAULT_UPPER_LIMIT);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [splashSrc, setSplashSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const closeButtonRef = useRef(null);
  const openButtonRef = useRef(null);
  const isFirstRender = useRef(true);

  const participants = useMemo(
    () =>
      Array.from(new Set(messages.map(({ author }) => author))).filter(
        author => author !== 'System',
      ),
    [messages],
  );

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const setMessageLimits = e => {
    const { lowerLimit: ll, upperLimit: ul } = Object.fromEntries(
      new FormData(e.currentTarget),
    );
    const lower =
      ll === '' ? DEFAULT_LOWER_LIMIT : parseInt(ll, 10) || DEFAULT_LOWER_LIMIT;
    const upper =
      ul === '' ? DEFAULT_UPPER_LIMIT : parseInt(ul, 10) || DEFAULT_UPPER_LIMIT;

    e.preventDefault();
    setLowerLimit(Math.min(lower, upper));
    setUpperLimit(Math.max(lower, upper));
  };

  useEffect(() => {
    window.onscroll = () => {
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.scrollHeight
      ) {
        setUpperLimit(upperLimit + DEFAULT_UPPER_LIMIT);
      }
    };
  }, [upperLimit]);

  useEffect(() => {
    const app = firebase.initializeApp(firebaseConfig);

    firebase.auth().onAuthStateChanged(user => {
      setIsLoading(false);
      setCurrentUser(user);

      if (user) {
        // User is signed in.
        const storage = app.storage();
        const chatRef = storage.ref('private/messages/_chat.txt');
        chatRef.getDownloadURL().then(url => {
          fetch(url)
            .then(response => response.text())
            .then(text => parseString(text, { parseAttachments: true }))
            .then(replaceEncryptionMessageAuthor)
            .then(setMessages)
            .catch(showError);
        });
      } else {
        // No user is signed in.
        const uiConfig = {
          signInFlow: 'popup',
          signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
          // tosUrl and privacyPolicyUrl accept either url string or a callback
          // function.
          // Terms of service url/callback.
          tosUrl: () => {
            alert('I love you cuchi cuchi');
          },
          // Privacy policy url/callback.
          privacyPolicyUrl: () => {
            alert('I love you cuchi cuchi');
          },
          callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
              // eslint-disable-next-line no-console
              console.log(authResult);
              console.log(redirectUrl);
              return false;
            },
          },
        };

        // Initialize the FirebaseUI Widget using Firebase.
        const ui = new firebaseui.auth.AuthUI(firebase.auth());
        // The start method will wait until the DOM is loaded.
        ui.start('#firebaseui-auth-container', uiConfig);
      }
    });
  }, [null]);

  useEffect(() => {
    if (isFirstRender.current) return;

    if (isMenuOpen) closeButtonRef.current.focus();
    else openButtonRef.current.focus();
  }, [isMenuOpen]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    const keyDownHandler = e => {
      if (e.keyCode === 27) closeMenu();
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => document.removeEventListener('keydown', keyDownHandler);
  }, []);

  useEffect(() => {
    setActiveUser(participants[0] || '');
  }, [messages]);

  useEffect(() => {
    const storage = firebase.storage();
    const chatRef = storage.ref('public/cover.jpeg');
    chatRef.getDownloadURL().then(url => {
      setSplashSrc(url);
    });
  }, [null]);

  if (isLoading) {
    return (
      <Loader
        style={{ textAlign: 'center' }}
        type="Puff"
        color="#ffe6e6"
        height={100}
        width={100}
      />
    );
  }

  return (
    <>
      {currentUser ? (
        <>
          <S.GlobalStyles />
          <S.Container>
            <MessageViewer
              messages={messages}
              participants={participants}
              activeUser={activeUser}
              lowerLimit={lowerLimit}
              upperLimit={upperLimit}
            />
            <S.MenuOpenButton
              type="button"
              onClick={openMenu}
              ref={openButtonRef}
            >
              Open menu
            </S.MenuOpenButton>
            <S.Overlay
              type="button"
              isActive={isMenuOpen}
              onClick={closeMenu}
              tabIndex="-1"
            />
            <S.Sidebar isOpen={isMenuOpen}>
              <S.MenuCloseButton
                type="button"
                onClick={closeMenu}
                ref={closeButtonRef}
              >
                Close menu
              </S.MenuCloseButton>
              <S.SidebarContainer>
                <S.Form onSubmit={setMessageLimits}>
                  <S.Fieldset>
                    <legend>Show messages from...</legend>
                    <S.Field>
                      <S.Label htmlFor="lower-limit">Start</S.Label>
                      <S.Input
                        id="lower-limit"
                        name="lowerLimit"
                        type="number"
                        min="1"
                        placeholder={lowerLimit}
                      />
                    </S.Field>
                    <S.Field>
                      <S.Label htmlFor="upper-limit">End</S.Label>
                      <S.Input
                        id="upper-limit"
                        name="upperLimit"
                        type="number"
                        min="1"
                        placeholder={upperLimit}
                      />
                    </S.Field>
                    <S.Field>
                      <S.Submit type="submit" value="Apply" />
                      {/* <S.InputDescription>
                        A high delta may freeze the page for a while, change
                        this with caution
                      </S.InputDescription> */}
                    </S.Field>
                  </S.Fieldset>
                  <S.Submit
                    type="button"
                    onClick={() => {
                      setUpperLimit(messages.length);
                      closeMenu();
                    }}
                    value="Show All"
                  />
                  <S.Field>
                    <S.Label htmlFor="active-user">
                      View as Jon or Jackie?
                    </S.Label>
                    <S.Select
                      id="active-user"
                      disabled={!participants.length}
                      value={activeUser}
                      onChange={e => {
                        setActiveUser(e.target.value);
                      }}
                    >
                      {participants.map(participant => (
                        <option key={participant} value={participant}>
                          {participant}
                        </option>
                      ))}
                    </S.Select>
                  </S.Field>
                </S.Form>
              </S.SidebarContainer>
            </S.Sidebar>
          </S.Container>
        </>
      ) : (
        <>
          <S.GuestGlobalStyles />
          <div style={{ textAlign: 'center' }}>
            {splashSrc ? (
              <img
                style={{ maxHeight: 350 }}
                src={splashSrc}
                alt="I love you"
              />
            ) : (
              ''
            )}{' '}
            <div id="firebaseui-auth-container" />
          </div>
        </>
      )}
    </>
  );
};

export default App;
